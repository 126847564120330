import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
//import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  description,
  row,
  //col6,
  col12,
  //marginTopSubstitute,
  introSection,
  buttonGroup,
} from "./subpage.module.scss";
//import { Link } from "gatsby";

const DMEA24Page = () => {
  return (
    <Layout pageTitle="DMEA24" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                 <p className={description}>Artikel - März 2024</p> 
                <h1>Versorgung erfolgreich vernetzen: Digitale Gesundheitsplattform in Rheinland-Pfalz</h1>
               
                <p className="text-medium">
                Regionale Vernetzung, Einbindung von Patientinnen und Patienten, Versorgungsqualität – diese Ziele der Politik lassen sich durch digitale Unterstützung erreichen. Vier Krankenhausträger in Rheinland-Pfalz setzen nun auf eine Lösung des Anbieters the i-engineers. Eine zentrale Rolle spielt bei der regionalen Drehscheibe für Kommunikation und Datenaustausch die Interoperabilität. (...)</p>
                </div>

                <div className={col12}>
                <p >Für alle, die erfahren möchten, warum sich 4 Krankenhausträger zur „Digitalen Gesundheitsplattform in Rheinland-Pfalz“ vernetzt und dafür die Lösung von uns gewählt haben, empfehlen wir diesen Artikel (PDF) aus dem KH-IT Journal:</p>
                <div style={{ marginBottom: 40, marginTop:15,}} className={buttonGroup}><a href="https://www.tie.ch/downloads/kh-it-1-24_rheinland-pfalz.pdf" className="button-outline blue" target="_blank" rel="noreferrer"> Zum vollständigen Artikel </a> </div>
                  </div>

                  <div className={col12}>
               
                <p>Bleiben Sie über neueste Informationen und Veranstaltungen von the i-engineers stets informiert und melden Sie sich für unseren Newsletter an.</p>
                <div className={buttonGroup}><a 
                    href="https://www.tie.ch/landingpages/newsletter-anmeldung"
                    className="button-outline blue" 
                    target="_blank" 
                    rel="noreferrer"> Zur Newsletter Anmeldung </a> </div>
                  </div>

             
            </div>

            
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default DMEA24Page;
